<template>
  <div class="recruit">
    <div class="category pc">
      <img src="../assets/img/logo/logo-category.svg" alt="" />
      <a class="before" href="#">Trang chủ</a>
      <span class="tag-mid">/</span>
      <a class="before" href="#">Liện Hệ</a>
      <span class="tag-mid">/</span>
      <a class="after" href="#">Tuyển Dụng</a>
    </div>
    <div class="recruit-main">
      <div class="recruit-main__title" data-aos="fade-up">
        <h5>THÔNG TIN TUYỂN DỤNG</h5>
        <h1>
          Hãy Gia Nhập Với Chúng Tôi <br />
          Để Hưởng Những Chế Độ Tốt Nhất
        </h1>
      </div>
      <div class="recruit-main__content" id="list-recruit">
        <div
          class="item"
          v-for="(item, index) in data"
          @click="active(index)"
          :key="index"
          data-aos="fade-up"
        >
          <div :class="getClass(index)">
            <p>{{ item[locale].vacancy }}</p>
            <img src="../assets/img/icons/arrow-down-orange.svg" alt="" />
          </div>
          <div :class="getClass2(index)">
            <p v-html="item[locale].content"></p>
            <a
              v-if="item.document"
              :href="item.document"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="upload-file" v-if="item.document">
                <img src="../assets/img/icons/upload-file.svg" alt="" />
                <span class="text-uppercase">{{ trans('frontend.button.download file') }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* global $ */
import RecruitsService from "@/services/RecruitsService";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      category: "Tin Tức",
      title: "Những Câu CHuyện Mới Nhất Trong Làng Golf",
      type: 0,
      data: [],
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.getData();
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    active(index) {
      //   $("#list-recruit .item").removeClass("active");
      // $("#list-recruit .item__title").removeClass("active");
      $(`#list-recruit .item-${index}`).toggle("active");
      $(`#list-recruit .item-title-${index}`).toggleClass("active");
    },
    async getData() {
      console.log("ha");
      await RecruitsService.recruits()
        .then((resp) => {
          this.data = resp.data.data;
        })
        .catch(() => {});
    },
    getClass(index) {
      return "item__title item-title-" + index;
    },
    getClass2(index) {
      return "item__content item-" + index;
    },
  },
};
</script>
